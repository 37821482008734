<template>
  <div>

    <div class="card-toolbar mb-5">
      <router-link v-if="$can('laundry_subscriptions.create')" class="btn btn-primary font-weight-bolder"
                   to="/pos/laundry-subscriptions/create">
        <v-icon>mdi-plus</v-icon>
        {{ $t('add_laundry_subscription') }}
      </router-link>
      <button class="btn btn-outline-primary font-weight-bolder ml-5" @click="openAdvancedSearch">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>
    <div v-if="showAdvancedSearch" class="card card-custom mb-5">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('expenses.from_date') }}</label>
              <input id="from_date" v-model="filters.start_date" class="form-control" type="date">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('expenses.to_date') }}</label>
              <input id="to_date" v-model="filters.end_date" class="form-control" type="date">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="currency_id">{{ $t('expenses.currency') }}</label>
              <select id="currency_id" v-model="filters.currency_id" class="custom-select" name="">
                <option v-for="row in currencies" :key="row.id" :value="row.id">
                  {{ row.name }}
                </option>
              </select>
            </div>

            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <button id="m_search" class="btn btn-primary mt-auto mr-2" type="button" @click="doFilter">
                <span><i class="fas fa-search"></i>
                  <span>{{ $t('search') }}</span>
                </span>
              </button>
              <button id="m_reset" class="btn btn-danger mt-auto mr-2" type="button" @click="resetFilter">
                <span><i class="fas fa-trash-restore"></i>
                  <span>{{ $t('reset_search') }}</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-body">
        <v-server-table ref="table" :class="'dataTable table-row-dashed'" :columns="columns" :options="options">
          <template slot="status" slot-scope="props">
            <b-form-checkbox v-if="$can('laundry_subscriptions.change_status')" :key="props.row.id"
                             v-model="props.row.is_active" :name="'check-button'+props.row.id"
                             size="lg" switch
                             @change="changeStatus(props.row.id, props.row.is_active)"></b-form-checkbox>
            <b-form-checkbox v-else :key="'check-button'+props.row.id" v-model="props.row.is_active"
                             :name="'check-button'+props.row.id" disabled
                             size="lg" switch></b-form-checkbox>
          </template>

          <template slot="actions" slot-scope="props">
            <v-btn v-if="$can('laundry_subscriptions.customers')" color="pink" icon @click="openModal(props.row)">>
              <v-icon v-b-tooltip.hover :title="$t('add_subscriber')" class="mr-2 text-info" color="blue darken-2"
                      small>
                mdi-account-plus
              </v-icon>
            </v-btn>
            <v-btn v-if="$can('laundry_subscriptions.customers')" :to="`/pos/laundry-subscriptions/add-subscriber/${props.row.id}`"
                   color="pink"
                   icon>
              <v-icon v-b-tooltip.hover :title="$t('add_subscribers')" class="mr-2 text-info" color="blue darken-2" small>
                mdi-account-multiple-plus
              </v-icon>
            </v-btn>
            <v-btn v-if="$can('laundry_subscriptions.update')" :to="`/pos/laundry-subscriptions/edit/${props.row.id}`"
                   color="pink"
                   icon>
              <v-icon v-b-tooltip.hover :title="$t('edit')" class="mr-2 text-info" color="blue darken-2" small>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-icon v-if="$can('laundry_subscriptions.delete')" v-b-tooltip.hover :title="$t('delete')"
                    class="text-danger" color="red darken-2"
                    small @click="deleteItem(props.row)">mdi-delete
            </v-icon>
            <v-btn v-b-tooltip.hover :title="$t('view')" :to="`/pos/laundry-subscriptions/view/${props.row.id}`"
                   color="pink"
                   icon>
              <v-icon class="mr-2" small>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="modal" :title="$t('add_subscriber')" hide-footer>
      <div class="row">
        <div class="col-lg-12 mb-5">
          <label>{{ $t('customer') }}</label>
          <div class="input-group">
            <multiselect v-model="customer"
                         :class="validation && validation.customer ? 'is-invalid' : ''"
                         :multiple="false"
                         :options="customers"
                         :placeholder="$t('customer')"
                         :show-labels="false"
                         :show-no-options="false"
                         :show-no-results="false"
                         :taggable="false"
                         label="name"
                         track-by="id"
                         @input="useCustomer"
                         @search-change="getCustomers($event)">
            </multiselect>
          </div>
          <span class="form-text text-muted">{{ $t('type_at_least_three_letters') + ' ' + $t('customer') }}.</span>
          <span v-if="validation && validation.customer" class="fv-plugins-message-container invalid-feedback">
            {{ validation.customer[0] }}
          </span>
        </div>
        <div class="col-lg-6 mb-5">
          <label>{{ $t('expenses_income_transaction.payment_method') }}<span class="text-danger">*</span></label>
          <select id="payment_method" v-model="form.payment_method"
                  :class="validation && validation.payment_method ? 'is-invalid' : ''" class="custom-select"
                  name="" @change="setPaymetType($event)">
            <option v-for="row in payment_methods" :key="row.id" :value="row.id">{{ row.title }}</option>
          </select>
          <span v-if="validation && validation.payment_method" class="fv-plugins-message-container invalid-feedback">
            {{ validation.payment_method[0] }}
          </span>
        </div>
        <div v-if="payment_method_type == 'bank'" class="col-lg-6 mb-5">
          <label>{{ $t('expenses_income_transaction.bank') }}<span class="text-danger">*</span></label>
          <div class="input-group">
            <multiselect v-model="bank"
                         :class="validation && validation.method_type_id ? 'is-invalid' : ''"
                         :multiple="false"
                         :options="banks"
                         :placeholder="$t('expenses.bank')"
                         :show-labels="false"
                         :show-no-options="false"
                         :show-no-results="false"
                         :taggable="false"
                         label="name"
                         track-by="id"
                         @input="useBank"
                         @search-change="getBanks($event)">
            </multiselect>
          </div>
          <span class="form-text text-muted">{{
              $t('type_at_least_three_letters') + ' ' + $t('payment_sales_invoices.banks')
            }}.</span>
          <span v-if="validation && validation.type_id" class="fv-plugins-message-container invalid-feedback">
            {{ validation.type_id[0] }}
          </span>
        </div>
        <div v-else-if="payment_method_type == 'treasury'" class="col-lg-6 mb-5">
          <label>{{ $t('expenses_income_transaction.treasury') }}<span class="text-danger">*</span></label>
          <div class="input-group">
            <multiselect v-model="treasury"
                         :class="validation && validation.type_id ? 'is-invalid' : ''"
                         :multiple="false"
                         :options="treasuries"
                         :placeholder="$t('expenses.treasury')"
                         :show-labels="false"
                         :show-no-options="false"
                         :show-no-results="false"
                         :taggable="false"
                         label="name"
                         track-by="id"
                         @input="useTreasury">
            </multiselect>
          </div>
          <span v-if="validation && validation.type_id" class="fv-plugins-message-container invalid-feedback">
            {{ validation.type_id[0] }}
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{ $t('close') }}</b-button>
        <b-button class="mt-2 mr-1" variant="primary" @click="save">{{ $t('save') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-laundry-subscriptions",
  components: {},
  data() {
    return {
      mainRoute: 'pos/laundry_subscriptions',
      routeChangeStatus: 'pos/laundry_subscription/change-status/',
      mainRouteDependency: 'base/dependency',
      showAdvancedSearch: false,
      filter: {
        sortBy: 'id',
      },
      filters: {
        name: null,
        currency_id: null,
        start_date: null,
        end_date: null,
      },
      columns: ['id', 'name', 'end_date', 'start_date', 'currency_name', 'descriptions', 'max_customers', 'status', 'actions'],
      data: [],
      currencies: [],
      innerId: null,
      statusId: null,
      validation: null,
      form: {
        amount: 0,
        payment_method: null,
        method_type_type: null,
        method_type_id: null,
        customer: {
          id: null,
          name: null,
        },
      },
      customers: [],
      customer: null,
      payment_method_type: null,
      payment_methods: [],
      banks: [],
      treasuries: [],
      bank: null,
      treasury: null,
      activeModalCustomerId: null,
      showCustomer: null
    }
  },
  watch: {
    'form.payment_method': function (val) {
      this.payment_method_type = null;
      if (val) {
        let _method = this.payment_methods.find(row => row.id == val);
        if (_method) {
          this.payment_method_type = _method.cash_type;
        }
      }
    },
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          id: that.$t('expenses.id'),
          name: that.$t('expenses.name'),
          end_date: that.$t('end_date'),
          start_date: that.$t('start_date'),
          currency_name: that.$t('expenses.currency'),
          descriptions: that.$t('descriptions'),
          max_customers: that.$t('max_customers'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: [],
        filterByColumn: false,
        filterable: false,
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.laundry_subscriptions")}]);
    this.getCurrencies();
  },
  methods: {
    showCustomers() {
      ApiService.get(`pos/laundry_subscription/customers_subscription/21`).then((response) => {
        this.showCustomer = response.data.data;
      });
    },
    resetForm() {
      this.form = {
        amount: 0,
        payment_method: null,
        method_type_type: null,
        method_type_id: null,
        customer: {
          id: null,
          name: null,
        },
      }
    },
    save() {
      let customers = [{...this.form}]
      ApiService.patch(`pos/laundry_subscription/customers_subscription/${this.activeModalCustomerId}`, {
        customers,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.hideModal();
        this.resetForm();
        this.customer = null;
      })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
            this.$errorAlert(error);
          });
    },
    setPaymetType(event) {
      switch (event.target.value) {
        case '1':
        case '2':
          this.form.method_type_type = 'Treasuries';
          break;
        case '3':
          this.form.method_type_type = 'Bank';
          break;
        case '6':
          this.form.method_type_type = 'CustomerBalances';
          break;
        default:
          this.form.method_type_type = null;
          break;
      }
    },
    useBank(obj) {
      this.form.method_type_id = obj.id;
    },
    useTreasury(obj) {
      this.form.method_type_id = obj.id;
    },
    useCustomer(obj) {
      this.form.customer.id = obj.id;
      this.form.customer.name = obj.name;
    },
    getBanks: _.debounce(function (filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/banks`, {params: {filter: filter}}).then((response) => {
          this.banks = response.data.data;
        });
      } else {
        this.banks = [];
      }
    }, 500),
    getCustomers: _.debounce(function (filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    }, 500),
    getTreasuries() {
      ApiService.get(`${this.mainRouteDependency}/treasuries`).then((response) => {
        this.treasuries = response.data.data;
      });
    },
    changeStatus(id, status) {
      console.log('sss')
      ApiService.patch(this.routeChangeStatus + id, {
        is_active: (status ? '1' : '0'),
        item_type: 1,
      }).then(response => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
        this.checkedRows = [];
      }).catch(error => {
        this.$errorAlert(error);
      });
    },
    getPaymentMethods() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = null;
      this.filters.currency_id = null;
      this.filters.code = null;
      this.filters.start_date = null;
      this.filters.end_date = null;
      this.filters.status = null;
      this.filters.expenses_category_id = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    loadOptions() {
    },
    updateValue(value) {
      this.filters.expenses_category_id = value
    },
    openModal(row) {
      this.activeModalCustomerId = row.id;
      this.form.amount = row.price;
      this.$refs.modal.show();
    },
    hideModal() {
      this.$refs.modal.hide();
    },
  },
  created() {
    this.getPaymentMethods();
    this.getTreasuries();
    this.showCustomers();
  }
};
</script>
